@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import '@fortawesome/fontawesome-free/css/all.min.css';


.animate-slideIn {
  @apply transition-transform duration-500 ease-in-out transform translate-y-10 opacity-100;
}

.animate-fadeIn {
  @apply transition-opacity duration-500 ease-in-out opacity-100;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}


.animate-fadeIn {
  opacity: 1;
  transform: translateY(0);
}

.transform {
  transform: translateY(20px);
}


.transition-opacity {
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.opacity-0 {
  opacity: 0;
  transform: translateY(20px);
}

.opacity-100 {
  opacity: 1;
  transform: translateY(0);
}