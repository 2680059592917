.menu-item a:hover {
  color: black;
  font-weight: 600;
}

.carousel {
  height: 85vh;
}

.footer {
  position: relative;
}

.h-100 {
  height: 100px !important;
}

.top-200 {
  top: 200px;
}
