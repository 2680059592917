/* Fade-in animations */
.fade-in-animation {
    animation: fadeIn 1.5s ease-in-out forwards;
  }
  .fade-in-delayed {
    animation: fadeIn 1.5s ease-in-out 0.5s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Confetti Styles */
  .confetti-piece {
    width: 6px;
    height: 12px;
    background-color: #ffdd57;
    position: absolute;
    border-radius: 2px;
    opacity: 0.8;
    top: -20px;
    left: calc(10% + 80% * var(--i));
    animation: none;
  }
  
  .animate-confetti {
    animation: confettiFall 2.5s ease-out infinite;
    animation-delay: calc(0.1s * var(--i));
  }
  
  @keyframes confettiFall {
    0% {
      transform: translateY(-50px) rotate(0deg);
    }
    100% {
      transform: translateY(200px) rotate(360deg);
    }
  }
  