/* Navbar.css */
.fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

.dropdown-enter {
  opacity: 0;
  transform: translateY(-10px) scale(0.95);
}

.dropdown-enter-active {
  opacity: 1;
  transform: translateY(0) scale(1);
  transition: opacity 300ms, transform 300ms;
}

.dropdown-exit {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.dropdown-exit-active {
  opacity: 0;
  transform: translateY(-10px) scale(0.95);
  transition: opacity 300ms, transform 300ms;
}

.logo {
  height: 100px;
  width: auto;
  border-radius: 0 50px 50px 0;
  left: 0;
  position: absolute;
  margin-right: 50px;
}

.calling-phone {
  height: 40px;
  position: fixed;
  /* Keeps it fixed in the viewport */
  top: 0;
  /* Adjust top distance */
  right: 0px;
  /* Adjust right distance */
  background-color: #111827;
  /* Background color for highlight */
  color: #fff;
  /* Text color */
  padding: 10px 50px;
  /* Padding for better appearance */
  border-radius: 0 0 50px 50px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow for depth */
  z-index: 1000;
  /* Ensures it stays on top of other content */
  font-size: 14px;
  /* Adjust font size as needed */
  text-decoration: none;
  /* Removes underline */
}
