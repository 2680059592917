/* For the sliding and fading animation */
.fade-enter {
    opacity: 0;
    max-height: 0;
    transform: translateY(-20px);
  }
  
  .fade-enter-active {
    opacity: 1;
    max-height: 1000px;
    transform: translateY(0);
    transition: all 300ms ease-in-out;
  }
  
  .fade-exit {
    opacity: 1;
    max-height: 1000px;
    transform: translateY(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    max-height: 0;
    transform: translateY(-20px);
    transition: all 300ms ease-in-out;
  }

  
  /* For the sliding and fading animation */
.fade-enter {
    opacity: 0;
    max-height: 0;
    transform: translateY(-20px);
  }
  
  .fade-enter-active {
    opacity: 1;
    max-height: 1000px;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms, max-height 300ms;
  }
  
  .fade-exit {
    opacity: 1;
    max-height: 1000px;
    transform: translateY(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    max-height: 0;
    transform: translateY(-20px);
    transition: opacity 300ms, transform 300ms, max-height 300ms;
  }
  