@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reveal animation when elements are in view */
.reveal {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

/* Additional Tailwind Customizations */
@layer utilities {
  .animate-on-scroll {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
  }
}

.bg-button {
  background-color: #111827;
}

.bg-button:hover {
  background-color: #111830 !important;
  color: #fff;
  font-weight: 400 !important;
  box-shadow: 0 5px 10px #fff;
}

.card-info {
  height: 100px;
  overflow-y: auto;
}

.trial-from {
  position: relative;
  margin-top: -300px;
  background-color: #dadaba40;
  border-radius: 5px;
}
.trial-from2 {
  position: relative;
  margin-top: -42%;
  background-color: #99999999;
  border-radius: 5px;
}

/* Media query for mobile devices (portrait) */
@media (max-width: 480px) {
  .trial-from2 {
    margin-top: -200%; /* For mobile portrait */
  }
}

/* Media query for mobile devices (landscape) */
@media (min-width: 481px) and (max-width: 768px) and (orientation: landscape) {
  .trial-from2 {
    margin-top: -0%; /* For mobile landscape */
  }
}

/* Media query for tablets (portrait) */
@media (min-width: 481px) and (max-width: 768px) {
  .trial-from2 {
    margin-top: -110%; /* For tablets portrait */
  }
}

/* Media query for tablets (landscape) */
@media (min-width: 769px) and (orientation: landscape) {
  .trial-from2 {
    margin-top: -30%; /* For tablets landscape */
  }
}
.bg-gred {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
}

.cust-border {
  border: 1px dashed #eeeeee50;
}

.card-border {
  padding: 20px; /* Adjust padding as needed */
  border: 5px solid transparent; /* Set a solid transparent border */
  border-image: linear-gradient(to bottom, #4e527f70, #b5a15850) 1; /* Gradient from yellow to white */
  border-radius: 10px; /* Optional: for rounded corners */
}

.bg-img {
  background: url("./assets/images/dollar.webp");
  background-size: cover;
}
